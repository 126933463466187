import { useEffect, useRef } from 'react';
import { withUIContext } from '@/context';
import { encodeTitle } from '@/helpers/_functions';
import { pushDataLayer } from '@/helpers';

import Icon from '../Icon';
import Text from '../Text';
import PopupShareStyle from './style';

const PopupShare = ({
  closePopup,
  title = '',
  text = '',
  showNotification,
  url,
  addUtmMedium,
}) => {
  const ref = useRef();

  const getUrl = (method) => {
    if (addUtmMedium) {
      const urlWithParams = new URL(url);
      switch (method) {
        case 'link':
          urlWithParams.searchParams.set('utm_medium', 'share_copylink');
          break;
        case 'fb':
          urlWithParams.searchParams.set('utm_medium', 'share_facebook');
          break;
        case 'tg':
          urlWithParams.searchParams.set('utm_medium', 'share_telegram');
          break;
        case 'wa':
          urlWithParams.searchParams.set('utm_medium', 'share_whatsapp');
          break;
        case 'email':
          urlWithParams.searchParams.set('utm_medium', 'share_email');
          break;
        case 'x':
          urlWithParams.searchParams.set('utm_medium', 'share_x');
          break;
      }
      return urlWithParams.toString();
    }
    return url;
  };

  const shareLink = (e) => {
    navigator.clipboard.writeText(getUrl('link'));
    pushDataLayer({
      event: 'clickShareBtn',
      shareType: 'copyLink',
      target: e.target,
      element: e.currentTarget,
    });
    closePopup();
    showNotification({
      type: 'success',
      title: 'Link copied to clipboard',
    });
  };

  useEffect(() => {
    if (!ref.current) return;
    pushDataLayer({
      event: 'openSharePopup',
      element: ref.current,
    });
  }, [ref.current]);

  const onClick = (e, shareType) => {
    pushDataLayer({
      event: 'clickShareBtn',
      target: e.target,
      element: e.currentTarget,
      shareType,
    });
  };

  return (
    <PopupShareStyle ref={ref}>
      <a
        href={`https://telegram.me/share/url?url=${encodeURIComponent(
          getUrl('tg')
        )}`}
        target='_blank'
        className={'share_button'}
        onClick={(e) => onClick(e, 'telegram')}
      >
        <Icon name={'telegram'} />
        <Text className={'font-avenir-regular'} text={'Telegram'} />
      </a>
      <a
        href={`https://wa.me?text=${encodeURIComponent(getUrl('wa'))}`}
        target='_blank'
        className={'share_button'}
        onClick={(e) => onClick(e, 'whatsapp')}
      >
        <Icon name={'whatsapp'} />
        <Text className={'font-avenir-regular'} text={'WhatsApp'} />
      </a>
      <a
        href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
          getUrl('fb')
        )}`}
        target='_blank'
        className={'share_button'}
        onClick={(e) => onClick(e, 'facebook')}
      >
        <Icon name={'facebook'} />
        <Text className={'font-avenir-regular'} text={'Facebook'} />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          getUrl('x')
        )}`}
        target='_blank'
        className={'share_button'}
        onClick={(e) => onClick(e, 'twitter')}
      >
        <Icon name={'twitter'} />
        <Text className={'font-avenir-regular'} text={'X'} />
      </a>
      <a
        href={`mailto:?subject=${title}&body=${encodeURIComponent(
          `${text}\n\n${getUrl('email')}`
        )}`}
        className={'share_button'}
        onClick={(e) => onClick(e, 'email')}
      >
        <Icon name={'mail'} />
        <Text className={'font-avenir-regular'} text={'Email'} />
      </a>
      <div className={'share_button'} onClick={shareLink}>
        <Icon name={'copy'} />
        <Text className={'font-avenir-regular'} text={'copyLink'} />
      </div>
    </PopupShareStyle>
  );
};

export default withUIContext(PopupShare, ['closePopup', 'showNotification']);
